<template>
  <div class="curva-list iq-style4 p-4 pl-5 mb-5">
    <b-row>
      <b-col lg="3" class="d-flex justify-content-center">
        <div>
          <input
              type="file"
              ref="fileInput"
              accept="image/*"
              style="display: none"
              @change="handleImageUpload"
          />
          <img
              class="circle-img"
              :src="info.image ? info.image : 'https://tse3.mm.bing.net/th?id=OIP.ruat7whad9-kcI8_1KH_tQHaGI&pid=Api&P=0'"
              @click="triggerFileInput"
          />
        </div>
      </b-col>
      <b-col lg="9">
        <b-row class="mb-3">
          <h4 class="text-warning info-name mx-2">{{info.name}}</h4>
          <img style="height: 21px; width: 21px;" :src="info.gender === 'male'? maleImg : femaleImg">
        </b-row>
        <b-row>
          <b-col lg="12"><p class="text-initial"><i class="text-warning las la-envelope mr-2"></i>{{ info.email }}</p></b-col>
          <b-col lg="12"><p class="text-initial"><i class="text-warning las la-phone mr-2"></i>{{ info.phone }}</p></b-col>
          <b-col lg="12"><p class="text-initial"><i
              class="text-warning las la-birthday-cake mr-2"></i>{{ info.birthdate }}</p></b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { core } from '@/config/pluginInit'
import ProfileServices from '@/modules/profile/services/profile.services'
export default {
  data () {
    return {
      info: {},
      userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
      maleImg: require('@/assets/images/curva/male.png'),
      femaleImg: require('@/assets/images/curva/female.png'),
      defaultImage: require('@/assets/images/user/default-user-image.png')
    }
  },
  methods: {
    triggerFileInput () {
      this.$refs.fileInput.click()
    },
    handleImageUpload (event) {
      const file = event.target.files[0]
      if (!file) return

      const reader = new FileReader()
      reader.onload = (e) => {
        this.info.image = e.target.result
      }
      reader.readAsDataURL(file)
      const formData = new FormData()
      formData.append('image', file)

      ProfileServices.changeProfileImage(formData).then(response => {
        this.userInfo.image = response.data.data.image
        localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
        core.showSnackbar('success', 'upload successfully')
      })
    },

    getProfileData () {
      ProfileServices.getProfileData().then(res => {
        this.info = res.data.data
        console.log(this.info)
      })
    },
    replaceByDefault (e) {
      this.info.image = this.defaultImage
    }
  },
  created () {
    this.getProfileData()
  },
  mounted () {
    core.index()
  }
}
</script>

<style>
.circle-img {
  object-fit: cover
}
</style>
